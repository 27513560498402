import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { urlConfig } from '../../assets/url.config';
import { ContactModel } from './../shared/models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor( private http: HttpClient ) { }

  public test(){
    return this.http.get<any>(urlConfig.message);
  }

  public submitMessage(contact: ContactModel): Observable<any> {
    return this.http.post<any>(urlConfig.message, contact)
    .pipe(
      take(1)
    );

  }

}
