import { LoginComponent } from './01-home/01.2-page/login/login.component';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './01-home/01.3-routing/app-routing.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared.module';
import { PageNotFoundComponent } from './01-home/01.2-page/pageNotFound/pageNotFound.component';
import { HomeComponent } from './01-home/01.2-page/home/home.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { ContactService } from './services/contact.service';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    LoginComponent,
    HomeComponent,
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    BrowserModule,
    SharedModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
